<template>
  <!-- 停靠点管理 -->
  <div ref="middleSiteManagement" class="middleSiteManagement">
    <div class="header">
      <p>停靠点管理</p>
      <el-button
        v-if="listFind('新增')"
        type="primary"
        size="small"
        @click="newMiddleSite"
      >
        <i
          class="iconfont its_tianjia"
          style="position: relative; top: 1px"
        ></i>
        新增停靠点
      </el-button>
    </div>
    <Table
      :col-class-type="true"
      :operation="true"
      :table-data="tableData"
      :title-name="titleName"
      :table-height="TableHeight"
      operation-width="150"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            v-if="listFind('编辑')"
            type="text"
            size="small"
            sort="primary"
            @click="editFun(scope.scopeRow)"
            >编辑</el-button
          >
          <el-button
            v-if="listFind('删除')"
            type="text"
            size="small"
            sort="danger"
            @click="deleteFun(scope.scopeRow)"
            >删除</el-button
          >
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, prev, pager, next, jumper, sizes"
        :total="total"
        :page-size="pageSize"
        :current-page="currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <charterPopup
      v-if="show"
      width="600"
      :header-text="headerText"
      @closePopupFun="closePopupFun"
      @confirmFun="confirmFun"
    >
      <!-- 新增停靠点 -->
      <div>
        <el-form
          ref="Form"
          :model="middleSiteForm"
          :rules="middleSiteRule"
          label-width="140px"
          label-position="right"
          class="demo-form dialog-form"
        >
          <el-form-item label="停靠点名称：" prop="siteName">
            <el-input
              v-model="middleSiteForm.siteName"
              placeholder="请输入停靠点名称"
              maxlength="10
              
              "
              onkeyup="this.value=this.value.replace(/\s+/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="启用：">
            <el-switch
              v-model="middleSiteForm.status"
              active-color="#13ce66"
              inactive-color="#E3E3E3"
              :active-value="0"
              :inactive-value="1"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
    </charterPopup>
  </div>
</template>

<script>
import {
  queryVillageSitePage,
  deleteVillageSiteById,
  saveVillageSite,
  updateVillageSite,
} from "@/api/lib/customizedBus.js";
export default {
  data() {
    return {
      middleSiteForm: {
        siteName: "",
        status: 0,
      },
      middleSiteRule: {
        siteName: [
          { required: true, message: "请填写停靠点名称", trigger: "blur" },
        ],
      },
      show: false,
      headerText: "",
      tableData: [],
      titleName: [
        {
          title: "停靠点名称",
          props: "siteName",
        },
        {
          title: "状态",
          props: "status",
          SpecialJudgment: (res) => (res ? "已禁用" : "已启用"),
        },
      ],
      total: 0,
      pageSize: 10,
      currentPage: 1,
      TableHeight: 0,
    };
  },
  mounted() {
    this.renderData();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  methods: {
    computeHeight() {
      const wholeHeight = this.$refs.middleSiteManagement.clientHeight;
      this.TableHeight = wholeHeight - 130 + "px";
    },
    // 点击表格编辑按钮
    editFun(scope) {
      this.show = true;
      this.headerText = "编辑";
      this.middleSiteForm = this.deepClone(scope);
    },
    // 点击表格删除按钮
    deleteFun(scope) {
      this.$confirm("确认删除吗？", "提示")
        .then(() => {
          deleteVillageSiteById({ id: scope.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.renderData();
            }
          });
        })
        .catch(() => {});
    },
    // 表格数据接口渲染
    renderData() {
      const obj = { currentPage: this.currentPage, pageSize: this.pageSize };
      queryVillageSitePage(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.currentPage = page;
      this.renderData();
    },

    // 当前条数改变
    onSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
      this.renderData();
    },
    // 点击新增停靠点
    newMiddleSite() {
      this.show = true;
      this.headerText = "新增";
    },
    // 弹窗确定按钮回调
    confirmFun() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          if (this.headerText == "编辑") {
            updateVillageSite(this.middleSiteForm).then((res) => {
              if (res.code == "SUCCESS") {
                this.renderData();
                this.closePopupFun();
                this.$message.success("修改成功");
              }
            });
          } else {
            const obj = {
              siteName: this.middleSiteForm.siteName,
              status: this.middleSiteForm.status,
            };
            saveVillageSite(obj).then((res) => {
              if (res.code == "SUCCESS") {
                this.renderData();
                this.closePopupFun();
                this.$message.success("新增成功");
              }
            });
          }
        }
      });
    },
    // 取消按钮回调
    closePopupFun() {
      this.show = false;
      this.middleSiteForm = {
        siteName: "",
        status: 0,
      };
    },
  },
};
</script>

<style scoped lang="scss">
.its_a-xuanzhongbeifen2 {
  color: #336fee;
}
.displayShow {
  display: inline-block;
  visibility: hidden;
}
.its_icon_more02 {
  color: #336fee;
  margin-left: 10px;
  position: relative;
  .menu {
    width: 80px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px #b4b2b2;
    border-radius: 4px;
    position: absolute;
    top: 22px;
    left: 10px;
    .li {
      text-align: center;
      color: #333333;
      font-size: 14px;
      width: 100%;
    }
    .li:hover {
      background: #336fee;
      color: #ffffff;
      border-radius: 4px;
      display: inline-block;
    }
  }
}
.checked {
  cursor: pointer;
  height: 40px;
  i {
    font-size: 14px;
    margin-right: 6px;
  }
  span {
    display: inline-block;
    font-size: 14px;
  }
  &:hover {
    .displayShow {
      visibility: inherit;
    }
  }
}
.green {
  color: #4fb638;
}
.red {
  color: #f74242;
}
.inputBlur {
  display: flex;
  .el-input {
    width: 200px;
  }
  i {
    cursor: pointer;
    margin-left: 12px;
    font-size: 18px;
  }
}
.addCounty {
  cursor: pointer;
  display: inline-block;
  i {
    font-size: 14px;
    color: #336fee;
    margin-right: 6px;
  }
  span {
    font-size: 14px;
    color: #336fee;
  }
}

.middleSiteManagement {
  width: calc(100% - 40px);
  height: calc(100% - 32px);
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  background: #ffffff;
  padding: 16px 20px;
  .header {
    height: 40px;
    margin-bottom: 18px;
    p {
      display: inline-block;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 40px;
    }
    .el-button {
      float: right;
      margin-top: 2px;
    }
  }
}
</style>
